/**
 * Komponente Modules
 */

import {
    needJquery,
    getTransitionDuration, extend
} from '../../shared/utils/index';

import Cart from './cart/cart';
import Events from './events/events';

// -------
// Private
// -------

const $ = needJquery();

// -------
// Public
// -------

const confirm = (opts) => {
    let o = $.extend(true, {}, {
            'type'      : 'primary',
            'callback'  : $.noop,
            'message'   : '',
            'noButton'  : 'Abbruch',
            'okButton'  : 'OK',
            'onComplete': $.noop,
            'title'     : 'Achtung'
        }, opts || {});

    let params = {
        'type': 'html',
        'src' : '<div class="fancybox-content fancybox-modal-content">' +
            '<h6 class="fb-modal-title">' + o.title + '</h6>' +
            '<p class="fb-modal-message">' + o.message + '</p>' +
            '<div class="clearfix fb-modal-controls">' +
            '<button class="btn btn-default btn-sm fb-modal-controls-cancel" data-value="0" data-fancybox-close>' + o.noButton + '</button>' +
            '<button class="btn btn-' + o.type + ' btn-sm fb-modal-controls-confirm" data-value="1" data-fancybox-close>' + o.okButton + '</button>' +
            '</div>' +
            '</div>' +
            '</div>',
        'opts': $.extend(true, {}, {
            'animationDuration' : 250,
            'animationEffect'   : 'zoom', // false, zoom, fade, zoom-in-out,
            'autoFocus'         : false,
            'baseClass'         : 'fb-custom'
        }, {
            'afterClose'  : function(instance, current, e) {
                var b = e ? e.target || e.currentTarget : null,
                    v = b ? $(b).data('value') : 0;

                o.callback(v);
            },
            'baseTpl'     : '<div class="fancybox-container modal-type-' + o.type + '" role="dialog" tabindex="-1">' +
                '<div class="fancybox-bg"></div>' +
                '<div class="fancybox-inner">' +
                '<div class="fancybox-stage"></div>' +
                '</div>' +
                '</div>',
            'clickOutside': false,
            'modal'       : true,
            'transitionEffect'  : 'circular' // false, fade, slide, circular, tube, zoom-in-out, rotate,
        })
    };

    $.fancybox.open(params);
};

const modules = () => {
	Events.init();
	Cart.init();
};

// -------
// Export
// -------
export default modules;
export {
    confirm
};
