/**
 * RequireJs Abhängigkeiten etc..
 *
 * @copyright 2017 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

var Main       = {},
	RjsAppPath = 'resources-deprecated/',
	Rjs;

/**
 * RequireJs-Konfiguration.
 *
 * @type {Object}
 */
Rjs = {
	urlArgs: '', // (new Date()).getTime()
	paths  : {
		// Hauptanwendung
		//'app'      : 'main',

		// Dateipfade
		'css'       : RjsAppPath + 'css',
		'libraries' : RjsAppPath + 'vendors/libraries',
		'module'    : RjsAppPath + 'js/module',
		'ext'       : RjsAppPath + 'js/ext',
		'controller': RjsAppPath + 'js/controller',
		'components': RjsAppPath + 'js/components',
		'plugins'   : RjsAppPath + 'vendors/plugins',
		'polyfills' : RjsAppPath + 'vendors/polyfills',
		'utils'     : RjsAppPath + 'js/utils',

		'main': RjsAppPath + 'js/main',
		'dev' : RjsAppPath + 'js/dev',

		// RequireJs-plugins
		'async'      : RjsAppPath + 'vendors/libraries/requirejs/async',
		'cssload'    : RjsAppPath + 'vendors/libraries/requirejs/css',
		'gmap-loader': RjsAppPath + 'vendors/libraries/requirejs/gmap-loader',

		// Abhaengigkeiten
		'jquery'                  : RjsAppPath + 'vendors/libraries/requirejs/jquery',
		// 'jquery-ui'          : RjsAppPath + 'vendors/libraries/requirejs/jquery-ui',
		'bootstrap'               : RjsAppPath + 'vendors/libraries/bootstrap/js/bootstrap',
		'bootstrap-select'        : RjsAppPath + 'vendors/plugins/bootstrap-select/js/bootstrap-select',
		'bootstrap-datetimepicker': RjsAppPath + 'vendors/plugins/bootstrap-datetimepicker/js/bootstrap-datetimepicker',
		// TODO(common.js): 'Bootstrap Fileinput' - Inaktiv
		// 'bootstrap-fileinput': RjsAppPath + 'vendors/plugins/bootstrap-fileinput/js/fileinput',
		'plugins-package'         : RjsAppPath + 'vendors/plugins/package',
		'matchheight'             : RjsAppPath + 'vendors/plugins/matchheight/js/matchheight',
		'moment'                  : RjsAppPath + 'vendors/plugins/momentjs/js/moment-with-locales',
		'readspeaker'             : '//f1-eu.readspeaker.com/script/8487/ReadSpeaker.js?pids=embhl',
		'sccookie' 				  : '../' + RjsAppPath + 'vendors/plugins/sc-cookie/js/sc-cookie.min'

		// TODO(common.js): 'iScroll' - Inaktiv
		// 'iscroll'         : {
		// 	deps   : ['jquery'],
		// 	exports: 'IScroll'
		// },
		// TODO(common.js): 'Sweet Alert' - Inaktiv
		// 'sweet-alert'     : {
		// 	deps   : ['jquery'],
		// 	exports: 'swal'
		// },
		// TODO(common.js): 'Pageflip' - Inaktiv
		// 'pageflip'        : [
		// 	'utils/pageflips'
		// ]
	},
	shim   : {
		// TODO(common.js): 'jQuery UI' - Abhängigkeiten inaktiv
		// 'jquery-ui'       : {
		// 	deps   : ['jquery'],
		// 	exports: '$UI'
		// },
		'bootstrap'               : {
			deps: ['jquery']
		},
		'bootstrap-select'        : {
			deps: [
				'jquery',
				'cssload!' + RjsAppPath + 'vendors/plugins/bootstrap-select/css/bootstrap-select.css'
			]
		},
		'bootstrap-datetimepicker': {
			deps: [
				'jquery',
				// 'cssload!../vendors/plugins/bootstrap-datetimepicker/css/bootstrap-datetimepicker.css',
				'moment'
			]
		},
		// TODO(common.js): 'Bootstrap Fileinput' - Abhängigkeiten inaktiv
		// 'bootstrap-fileinput': {
		// 	deps: [
		// 		'jquery',
		// 		'cssload!../vendors/plugins/bootstrap-fileinput/css/bootstrap-fileinput.css'
		// 	]
		// },
		'moment'                  : {
			'exports': 'M'
		},
		'dev'                     : {
			deps: ['jquery']
		},
		'main'                    : {
			deps: [
				'jquery', 'bootstrap', 'matchheight', 'plugins-package', 'cssload!' + RjsAppPath + 'vendors/libraries/readspeaker/ReadSpeaker.Styles'
			]
		},
		'matchheight'             : {
			deps: ['jquery']
		},
		'plugins-package'         : {
			deps: ['jquery']
		},
		'readspeaker'             : {
			deps: ['cssload!' + RjsAppPath + 'vendors/libraries/readspeaker/ReadSpeaker.Styles']
		}//,
		// TODO(common.js): 'iScroll' - Abhängigkeiten inaktiv
		// 'iscroll'         : {
		// 	deps   : ['jquery'],
		// 	exports: 'IScroll'
		// },
		// TODO(common.js): 'Pageflip' - Abhängigkeiten inaktiv
		// 'pageflip'        : {
		// 	deps: [
		// 		'jquery',
		// 		'cssload!../vendors/libraries/pageflip5/css/pageflip5.css',
		// 		'../vendors/libraries/pageflip5/js/pageflip5'
		// 	]
		// },
		// TODO(common.js): 'Cookie' - Abhängigkeiten inaktiv
		//'cookie' : {
		//	deps: ['jquery']
		//}
	},
	onError: function(err) {
		'use strict';

		alert(err.requireType);

		if (err.requireType === 'timeout')
		{
			alert('Modul: ' + err.requireModules);
		}
	}
};

/**
 * Initialisierung RequireJs
 */
(function() {
	'use strict';

	require.config(Rjs);

	// require(['main', 'dev'], function(cb) {
	require(['main'], function(cb) {
		// require(['main'], function(cb) {
		cb();
		// Nur Abhaengigkeiten 'Shim' laden!
	});
}());