/**
 * Account
 */

import {needJquery} from '../../../../shared/utils/index';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator    from '../../../../shared/dom/manipulator';
import EventHandler   from '../../../../shared/dom/event-handler';
import Drawer from "../../../../shared/components/drawer/drawer";
import AjaxRequest from "../../../../shared/utils/ajax";

import Login from '../login/login';

// -------
// Private
// -------

const renderDrawer = (container, toggleCollection) => {
	const AccountDrawer = new Drawer(container);

    $(document).on('click', '[data-drawer-account]', function(event) {
        event.preventDefault();

        AccountDrawer.show();
    });
};

// -------
// Public
// -------

const buildAccount = (o) => {
	const user  = o.account.user;
	const links = o.account.links;

	if(user && links) {
		const pageHeaderUserName = SelectorEngine.findOne('#page-header .user-name');
		const drawerHeader       = SelectorEngine.findOne('#page-account .drawer__header .drawer-title');
		const drawerBodyNav      = SelectorEngine.findOne('#page-account .drawer__body .nav.account-nav > .nav-list');

		if(pageHeaderUserName) {
			pageHeaderUserName.innerHTML = user.full_name;
		}

		// if(drawerHeader) {
		// 	console.log(drawerHeader);
		//
		// 	setTimeout(function() {
		// 		drawerHeader.innerHTML = user.full_name;
		// 	}, 200);
		// }

		if(drawerBodyNav) {
			drawerHeader.innerHTML = '';

			for (const [key, value] of Object.entries(links)) {
				const link = Manipulator.createElementFrom(`<li class="nav-item"><a class="nav-link" href="${value.href}">${value.label}</a></li>`);
				Manipulator.elementAppend(link, drawerBodyNav);
			}
		}
	}
};

const init = () => {
	const container        = SelectorEngine.findOne(`.drawer#page-account`);
	const toggleCollection = SelectorEngine.find(`[data-drawer-account]`);

	if(container && toggleCollection) {
		renderDrawer(container, toggleCollection);
	}
};

// -------
// Export
// -------

export default {
	init         : init,
	buildAccount : buildAccount
};